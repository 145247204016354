@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic");

:root {
	--overlay-color: rgba(0, 0, 0, .7);
	--dialog-color: white;
	--dialog-border-radius: 20px;
	--icon-color: rgba(73, 80, 87, .6);
	--dialog-padding: 20px;
	--drag-over-background: #e3e5e8;
	--file-infos-border: dashed 2px #91c7d263;
	--view-id-border: 1px solid rgba(225, 225, 225, 0.8);
	--facebook-App_id-border: 1px solid rgba(225, 225, 225, 0.8);
	--facebook-App_secret-border: 1px solid rgba(225, 225, 225, 0.8);
	--facebook-Ad_Account_Id-border: 1px solid rgba(225, 225, 225, 0.8);
	--client_id-border: 1px solid rgba(225, 225, 225, 0.8);
	--client_secret-border: 1px solid rgba(225, 225, 225, 0.8);
	--access_token-border: 1px solid rgba(225, 225, 225, 0.8);
	--account_id-border: 1px solid rgba(225, 225, 225, 0.8);
	--google-customer_id-border: 1px solid rgba(225, 225, 225, 0.8);

}

.container_form {
	width: 100%;
	// height: 100%;
	position: fixed;
	background-color: rgb(30 37 56 / 60%);
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// display: none;
	padding: 15px 15px 15px 15px;

}

.file-container {
	font-size: 13pt;
	color: #4d4d4d;
	background-color: #424242;
	border-radius: 15px;
	padding: 20px;
	// box-shadow: 0px 1px 15px 1px rgba(225, 225, 225, 0.5);
	background: #1F1F1F;
	margin: auto;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	max-width: 625px;
	width: 100%;

	@media (max-width: $desktop_view) {
		max-height: 520px;
		overflow-y: auto;
		// height: 100%;
	}

	.file-wrapper {
		position: relative;
		background-color: transparent;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 20;
		border-radius: 7px;
		padding: 0 20px;
		color: white;

		.file-input {
			position: absolute;
			width: 100%;
			// height: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: #000;
			z-index: 10;
			cursor: pointer;
			opacity: 0;
		}

		.file-content {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: var(--dialog-border-radius);
			transition: 0.2s;

			.mainheader {
				font-size: 20px;
				text-align: center;
				font-weight: 500;
				padding: 15px 0 5px 0;
			}

			.subheader {
				// opacity: .5;
				font-weight: 300;
				// text-align: center;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 1px;
				color: #ffffff80;
				text-align: justify;
				padding-bottom: 10px;

				a {
					color: #FA8C16;
				}

			}

			.file-infos {
				position: relative;
				display: flex;
				min-height: 202px;
				flex-direction: column;
				justify-content: center;
				margin: auto;
				background-color: #dcf9ff21;
				border-radius: var(--dialog-border-radius);
				padding: var(--dialog-padding);
				transition: 0.2s;
				border: var(--file-infos-border);

				.file-icon {
					position: relative;
					width: 100%;
					height: 100%;
					margin: 0;
					color: var(--icon-color);

					img {
						margin: 20px auto;
						width: 60px;
						color: #FA8C16;
					}

					.icon-shadow {
						position: relative;
						display: block;
						width: 95px;
						height: 7px;
						border-radius: 100%;
						background-color: #a4a4a5;
						top: -17px;
						margin-left: auto;
						margin-right: auto;
					}

					>span {
						position: absolute;
						bottom: var(--dialog-padding);
						width: calc(100% - var(--dialog-padding) * 2);

						span {
							display: none;
						}

						.has-drag {
							display: inline;
						}

					}
				}

			}

			.file-name {
				text-align: middle;
				font-weight: bold;
				font-size: 15pt;
				margin-top: 15px;
				text-align: center;
			}
		}
	}

}

.file-container .file-wrapper .file-input--active+.file-content {
	background: #ffffff36;
	color: white;
	padding: 10px 20px;

}

.file-container .file-wrapper .file-input--active+.file-content .file-icon {
	opacity: 1;
}

.file-container .file-wrapper .file-input--active+.file-content .file-icon img {
	animation-name: bounce;
	animation-duration: 0.6s;
	animation-iteration-count: infinite;
	animation-timing-function: ease;
}

.file-container .file-wrapper .file-input--active+.file-content .file-icon .icon-shadow {
	animation-name: shrink;
	animation-duration: 0.6s;
	animation-iteration-count: infinite;
}

.file-container .file-wrapper .file-content .file-infos .file-icon>span .file-container .file-wrapper .file-content .file-infos .file-icon>span .fa_folder_open {
	height: 50px;
	width: 50px;
}

.file-container .file-wrapper .file-content .file-infos .file-icon img,
.file-container .file-wrapper .file-content .file-infos .file-icon span {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
}

@keyframes bounce {
	0% {
		transform: translateY(0px);
	}

	50% {
		transform: translateY(-15px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes shrink {
	0% {
		width: 95px;
	}

	50% {
		width: 75px;
	}

	100% {
		width: 95px;
	}
}

.upload-text {
	display: flex;
	flex-flow: column;
	align-items: center;
	left: calc(50% - 59px);
	font-size: 14px;
	color: #b7b7b7;
	line-height: 26px;
}

.bold {
	font-weight: 500;
	margin: 5px 0;
	font-size: 12px;
	color: grey;
}

.upload-text button,
input[type="button"] {
	padding: 8px 12px;
	border: 0;
	color: white;
	border-radius: 7px;
	background: #FA8C16;
	font-size: 12px;
}

input[type="button"] {
	background: #FA8C16;
	width: 100%;
	padding: 12px;
	max-width: 625px;
	width: 100%;
	font-size: large;
	margin-bottom: 15px;
}


.view_id {
	width: 100%;
	// min-width: 400px;
	background-color: transparent;
	color: white;
	max-width: 625px;
	width: 100%;
	margin: 0;
	border-radius: 7px;
	font-family: "Rubik", sans-serif;
	/* color: grey; */
	margin-bottom: 15px;
	/* height: 42px; */
	padding: 11px;
	padding-left: 10px;
	outline-width: 0px;
	outline-color: rgba(255, 255, 255, 0);
	border-color: rgba(255, 255, 255, 0);
	border: var(--view-id-border)
}

.App_id,
.App_secret,
.Ad_Account_Id,
.customer_id {
	width: 100%;
	// min-width: 400px;
	background-color: transparent;
	color: white;
	max-width: 625px;
	width: 100%;
	margin: 0;
	border-radius: 7px;
	font-family: "Rubik", sans-serif;
	/* color: grey; */
	margin-bottom: 15px;
	/* height: 42px; */
	padding: 11px;
	padding-left: 10px;
	outline-width: 0px;
	outline-color: rgba(255, 255, 255, 0);
	border-color: rgba(255, 255, 255, 0);
	border: 1px solid rgba(225, 225, 225, 0.8);
}

.App_id {
	border: var(--facebook-App_id-border);
}

.App_secret {
	border: var(--facebook-App_secret-border);

}

.Ad_Account_Id {
	border: var(--facebook-Ad_Account_Id-border);

}

.customer_id {
	border: var(--google-customer_id-border);

}

.facebook-red-border-color .google-red-border-color {
	border: 1px solid rgb(140, 0, 0);
}

.close-btn {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	button {
		margin-top: -1px;
		border-radius: 8px;
		background: transparent;
		color: white;
	}
}



// left: 50%;

// .TopPerformingReportDropdown {
// 	margin-right: 0px !important;

// }

.Selected_ads_option {
	max-width: 200px;
	position: relative;
	margin-right: 48px;
	margin-top: 8px;

	.sub_dropdown {
		margin-bottom: 10px;
	}

	.close_icon {
		float: right;
		font-size: 2rem;
		font-weight: bold;
		line-height: 1;
		color: #ff9000;
		position: absolute;
		top: 2px;
		right: 3px;
		font-size: 25px;
		font-weight: bold;
		text-decoration: none;
	}

	.new_select_box_sec {
		&:last-child {
			padding-right: 0px !important;
		}

		// @media(max-width: $large_mobile) {
		//    margin: 10px 0;
		//  }
		.css-1xc3v61-indicatorContainer:first-child,
		.css-15lsz6c-indicatorContainer:first-child {
			display: none;
		}

		.css-3w2yfm-ValueContainer,
		.css-1dyz3mf {
			// min-height: 80px;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: 80px;

			@media(max-width: $large_mobile) {
				max-height: 35px;
			}

			.css-1p3m7a8-multiValue {
				padding: 0 5px;
				background-color: #fa8c16;
				border-radius: 20px;

				.css-wsp0cs-MultiValueGeneric,
				.css-9jq23d {
					color: #fff;
					font-size: 13px;
					line-height: 19px;

					@media(max-width: $large_mobile) {
						font-size: 12px;
						line-height: 16px;
					}
				}

				.css-12a83d4-MultiValueRemove,
				.css-v7duua {
					display: none;
				}
			}

			&::-webkit-scrollbar {
				width: 7px;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px grey;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				background: $orange_color;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: $orange_color;
			}
		}

		.css-1nmdiq5-menu {
			// margin-left: -40px;
			width: 150px !important;
		}

		.css-d7l1ni-option,
		.css-tr4s17-option {
			background-color: #fa8c16 !important;
		}

		.css-tr4s17-option,
		.css-d7l1ni-option,
		.css-10wo9uf-option {
			display: flex;
			align-items: baseline;

			input[type="checkbox"] {
				margin-right: 10px;
			}

			label {
				word-break: break-all;
			}
		}

		&:first-child {
			.css-1nmdiq5-menu {
				margin-left: 0px;
			}
		}
	}

	.new_select_box_sec {
		cursor: $cursor_pointer;
		// padding-bottom: 10px;
		padding-right: 10px;
		max-width: 33.33%;
		width: $w_100;
		min-width: 217px;


		@media (max-width: $desktop) {
			max-width: 50%;
		}

		@media (max-width: $mobile) {
			min-width: 130px;
			max-width: $w_100;
			padding-right: 5px;
			max-width: 33.33%;
		}

		@media (max-width: $ipad) {
			max-width: 50%;
		}

		@media (max-width: $mobileLarge) {
			max-width: 100%;
		}

		.css-1nmdiq5-menu {
			// margin-left: -40px;
			width: 150px !important;
		}

		.css-13cymwt-control,
		.css-t3ipsp-control {
			// min-height: 34px;
			// height: 34px;
			cursor: $cursor_pointer;
			background-color: $tooltip_background;
			border: unset;
			box-shadow: unset;

			.css-1hb7zxy-IndicatorsContainer {
				.css-1u9des2-indicatorSeparator {
					display: $d_none;
				}
			}

			.css-1fdsijx-ValueContainer {
				.css-1dimb5e-singleValue {
					color: $white;
				}
			}

			.css-1hb7zxy-IndicatorsContainer {

				.css-1xc3v61-indicatorContainer,
				.css-15lsz6c-indicatorContainer {
					color: $lighter_white;

					&:hover {
						color: $lighter_white;
					}
				}
			}

			.css-hlgwow {
				>div {
					color: $white;
				}
			}

			.css-1wy0on6 {
				>span {
					display: $d_none;
				}
			}
		}

		>div:nth-child(4) {
			display: $d_block !important;
			background-color: $tooltip_background;
			color: $white;
			width: max-content;
			max-width: 200px;
			min-width: $w_100;
			z-index: 8;

			.css-1n6sfyn-MenuList {
				.css-tr4s17-option {
					background: unset;
					background-color: #fa8c16;
				}

				>div {
					cursor: $cursor_pointer;

					&.css-d7l1ni-option {
						background: $orange_col;
					}

					&:hover {
						background: $orange_col !important;

						&[aria-disabled="true"] {
							background: unset !important;
						}
					}
				}

				&::-webkit-scrollbar {
					width: 7px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: $orange_color;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb:hover {
					background: $orange_color;
				}
			}

			>div {
				&::-webkit-scrollbar {
					width: 7px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: $orange_color;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb:hover {
					background: $orange_color;
				}

				>div {
					background-color: unset;

					&:hover {
						background-color: $orange_color;
						cursor: pointer;
					}
				}
			}
		}

		>div {
			background: $border_color;
			border: unset;

			>div:nth-child(2) {
				>span {
					display: $d_none;
				}
			}
		}


	}


}

.hader_search_box {
	margin-right: 10px !important;

	.search_input {
		width: 185px;
		height: 38px;
		color: white;
		background-color: #424242;
		border-radius: 5px;
		border: 0;
		outline: none;
		margin-bottom: 10px;
		padding: 0px 12px;


		&::placeholder {
			// Applying styles to placeholder
			color: white;
		}
	}


}


.Treatment_Multiselect {
	max-width: 200px;
	position: static;
	margin-right: 23px;
	margin-top: 11px;

	.sub_dropdown {
		margin-bottom: 10px;
	}

	.close_icon {
		float: right;
		font-size: 2rem;
		font-weight: bold;
		line-height: 1;
		color: #ff9000;
		position: absolute;
		top: 2px;
		right: 3px;
		font-size: 25px;
		font-weight: bold;
		text-decoration: none;
	}

	.new_select_box_sec {
		&:last-child {
			padding-right: 0px !important;
		}

		// @media(max-width: $large_mobile) {
		//    margin: 10px 0;
		//  }
		.css-1xc3v61-indicatorContainer:first-child,
		.css-15lsz6c-indicatorContainer:first-child {
			display: none;
		}

		.css-3w2yfm-ValueContainer,
		.css-1dyz3mf {
			// min-height: 80px;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: 30px;

			@media(max-width: $large_mobile) {
				max-height: 35px;
			}

			.css-1p3m7a8-multiValue {
				padding: 0 5px;
				background-color: #fa8c16;
				border-radius: 20px;

				.css-wsp0cs-MultiValueGeneric,
				.css-9jq23d {
					color: #fff;
					font-size: 13px;
					line-height: 19px;

					@media(max-width: $large_mobile) {
						font-size: 12px;
						line-height: 16px;
					}
				}

				.css-12a83d4-MultiValueRemove,
				.css-v7duua {
					display: none;
				}
			}

			&::-webkit-scrollbar {
				width: 7px;
			}

			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 5px grey;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				background: $orange_color;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: $orange_color;
			}
		}

		.css-1nmdiq5-menu {
			// margin-left: -40px;
			width: 150px !important;
		}

		.css-d7l1ni-option,
		.css-tr4s17-option {
			background-color: #fa8c16 !important;
		}

		.css-tr4s17-option,
		.css-d7l1ni-option,
		.css-10wo9uf-option {
			display: flex;
			align-items: baseline;

			input[type="checkbox"] {
				margin-right: 10px;
			}

			label {
				word-break: break-all;
			}
		}

		&:first-child {
			.css-1nmdiq5-menu {
				margin-left: 0px;
			}
		}
	}

	.new_select_box_sec {
		cursor: $cursor_pointer;
		// padding-bottom: 10px;
		padding-right: 10px;
		max-width: 33.33%;
		width: $w_100;
		min-width: 217px;


		@media (max-width: $desktop) {
			max-width: 50%;
		}

		@media (max-width: $mobile) {
			min-width: 130px;
			max-width: $w_100;
			padding-right: 5px;
			max-width: 33.33%;
		}

		@media (max-width: $ipad) {
			max-width: 50%;
		}

		@media (max-width: $mobileLarge) {
			max-width: 100%;
		}

		.css-1nmdiq5-menu {
			// margin-left: -40px;
			width: 150px !important;
		}

		.css-13cymwt-control,
		.css-t3ipsp-control {
			// min-height: 34px;
			// height: 34px;
			cursor: $cursor_pointer;
			background-color: $tooltip_background;
			border: unset;
			box-shadow: unset;

			.css-1hb7zxy-IndicatorsContainer {
				.css-1u9des2-indicatorSeparator {
					display: $d_none;
				}
			}

			.css-1fdsijx-ValueContainer {
				.css-1dimb5e-singleValue {
					color: $white;
				}
			}

			.css-1hb7zxy-IndicatorsContainer {

				.css-1xc3v61-indicatorContainer,
				.css-15lsz6c-indicatorContainer {
					color: $lighter_white;

					&:hover {
						color: $lighter_white;
					}
				}
			}

			.css-hlgwow {
				>div {
					color: $white;
				}
			}

			.css-1wy0on6 {
				>span {
					display: $d_none;
				}
			}
		}

		>div:nth-child(4) {
			display: $d_block !important;
			background-color: $tooltip_background;
			color: $white;
			width: max-content;
			max-width: 200px;
			min-width: $w_100;
			z-index: 8;

			.css-1n6sfyn-MenuList {
				.css-tr4s17-option {
					background: unset;
					background-color: #fa8c16;
				}

				>div {
					cursor: $cursor_pointer;

					&.css-d7l1ni-option {
						background: $orange_col;
					}

					&:hover {
						background: $orange_col !important;

						&[aria-disabled="true"] {
							background: unset !important;
						}
					}
				}

				&::-webkit-scrollbar {
					width: 7px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: $orange_color;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb:hover {
					background: $orange_color;
				}
			}

			>div {
				&::-webkit-scrollbar {
					width: 7px;
				}

				&::-webkit-scrollbar-track {
					box-shadow: inset 0 0 5px grey;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					background: $orange_color;
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb:hover {
					background: $orange_color;
				}

				>div {
					background-color: unset;

					&:hover {
						background-color: $orange_color;
						cursor: pointer;
					}
				}
			}
		}

		>div {
			background: $border_color;
			border: unset;

			>div:nth-child(2) {
				>span {
					display: $d_none;
				}
			}
		}


	}


}

.Top_Performing_Report_DropDown {
	display: flex;
	align-items: center;
	margin-top: 3px;

	@media (max-width: $ipad) {
		display: none;
	}
}