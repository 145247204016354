@mixin width($maxWidth) {
    max-width: $maxWidth;
    width: $w_100;
}

@mixin container {
    @include width(1200px);
    margin: $m_auto;
    padding: 0 20px;
}

@mixin chart_text {
    .main_chart_text {
        max-width: 150px;
        width: 100%;
        text-align: center;
        margin: auto;
        padding: 0 10px;
    }

    foreignObject {
        align-items: center;
        // height: 100% !important;
        width: 151px !important;


        >div {
            white-space: normal !important;
        }
    }
}

@mixin filter {
    display: $d_flex;
    align-items: $align_items_center;

    .select_box_sec {
        cursor: $cursor_pointer;
        // padding-right: 10px;
        margin: 5px;
        max-width: 130px;
        width: $w_100;
        min-width: 111px;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            min-height: 34px;
            height: 34px;
            cursor: $cursor_pointer;
            background-color: $tooltip_background;
            border: unset;
            box-shadow: unset;

            .css-1hb7zxy-IndicatorsContainer {
                .css-1u9des2-indicatorSeparator {
                    display: $d_none;
                }
            }

            .css-1fdsijx-ValueContainer {
                .css-1dimb5e-singleValue {
                    color: $white;
                }
            }

            .css-1hb7zxy-IndicatorsContainer {

                .css-1xc3v61-indicatorContainer,
                .css-15lsz6c-indicatorContainer {
                    color: $lighter_white;

                    &:hover {
                        color: $lighter_white;
                    }
                }
            }

            .css-hlgwow {
                >div {
                    color: $white;
                }
            }

            .css-1wy0on6 {
                >span {
                    display: $d_none;
                }
            }
        }

        >div:nth-child(4) {
            display: $d_block !important;
            background-color: $tooltip_background;
            color: $white;
            width: max-content;
            max-width: 160px;
            min-width: $w_100;
            z-index: 8;
            max-height: 300px;
            overflow: hidden;

            .css-1n6sfyn-MenuList {
                .css-tr4s17-option {
                    background: unset;
                    background-color: unset;
                }

                >div {
                    cursor: $cursor_pointer;

                    &.css-d7l1ni-option {
                        background: $orange_col;
                    }

                    &:hover {
                        background: $orange_col !important;

                        &[aria-disabled="true"] {
                            background: unset !important;
                        }

                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $orange_color;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $orange_color;
                }
            }

            >div {
                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px grey;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $orange_color;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $orange_color;
                }

                >div {
                    background-color: unset;

                    &:hover {
                        background-color: $orange_color;
                        cursor: pointer;
                    }
                }
            }

        }

        >div {
            background: $border_color;
            border: unset;

            >div:nth-child(2) {
                >span {
                    display: $d_none;
                }
            }
        }
    }

    @media(max-width: $mobile) {
        flex-wrap: $flex_wrap;
    }
}

@mixin all_btn {
    background: $orange_col;
    border: 1px solid $orange_col;
    padding: 5px 15px;
    font-size: $f14;
    line-height: $line_height_22;
    color: $white;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: $border_radius_2;
    transition: 0.4s ease;
    min-width: 170px;
    cursor: $cursor_pointer;

    &:hover {
        background: $white;
        color: $orange_col;
    }
}

@mixin responsive_filter {
    .chart_title_sec {
        .chart_heading_sec {
            .title_text.add_dots {
                @media(max-width: $mobileLarge) {
                    max-width: 100px;
                }
            }
        }
    }

    .filter_sec {
        max-width: unset;
        flex-wrap: nowrap;
        width: auto;

        @media(max-width: $mobile) {
            .select_box_sec {
                padding-right: 2px;
            }
        }

        @media(max-width: $mobileLarge) {
            .select_box_sec {
                min-width: unset;
                width: unset;
            }

            .css-13cymwt-control {
                .css-1fdsijx-ValueContainer {
                    padding: 2px 3px;
                }

                .css-1xc3v61-indicatorContainer {
                    padding: 8px 2px;

                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
}

// @mixin media_query($breakpoint) {
//     @if $breakpoint ==extraLarge {
//         @media screen and (max-width: $large) {
//             @content; // 1366px
//         }
//     }

//     @else if $breakpoint ==Large {
//         @media screen and (max-width: $ipad) {
//             @content; // 989px
//         }
//     }

//     @else if $breakpoint ==Medium {
//         @media screen and (max-width: $mobileLarge) {
//             @content; // 767px
//         }
//     }

//     @else if $breakpoint ==Small {
//         @media screen and (max-width: $mobile) {
//             @content; // 579px
//         }
//     }
// }