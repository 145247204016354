

//poppins

@font-face {
    font-family: Poppins-Thin;
    src: url(../fonts/Poppins-Thin.woff2);
}

@font-face {
    font-family: Poppins-ExtraLight;
    src: url(../fonts/Poppins-ExtraLight.woff2);
}


@font-face {
    font-family: Poppins-Light;
    src: url(../fonts/Poppins-Light.woff2);
}

@font-face {
    font-family: Poppins-Regular;
    src: url(../fonts/Poppins-Regular.woff2);
}


@font-face {
    font-family: Poppins-Medium;
    src: url(../fonts/Poppins-Medium.woff2);
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url(../fonts/Poppins-SemiBold.woff2);
}


@font-face {
    font-family: Poppins-Bold;
    src: url(../fonts/Poppins-Bold.woff2);
}

@font-face {
    font-family: Poppins-ExtraBold;
    src: url(../fonts/Poppins-ExtraBold.woff2);
}


@font-face {
    font-family: Poppins-Black;
    src: url(../fonts/Poppins-Black.woff2);
}

