.m-grid {
    >.m-header {
        position: $position_sticky;
        top: 0;
        z-index: 10;
        height: unset !important;

        >div {
            >div {
                position: relative;

                .m-brand {
                    background: $dark_color;
                    padding: 0 15px;
                    border-right: 1px solid $border_color;
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 102px !important;

                    @media (max-width: 992px) {
                        position: unset;
                        height: 75px !important;
                    }

                    >div {
                        position: $position_relative;

                        .m-brand__logo {
                            height: 70px !important;
                            display: $d_flex;
                            align-items: $align_items_center;
                            justify-content: $justify_content_center;

                            >a {
                                >img {
                                    height: 70px !important;
                                    width: auto !important;
                                    max-height: 55.75px;
                                }
                            }

                            @media (max-width: $larges) {
                                vertical-align: middle;
                                display: table-cell;
                                justify-content: unset;
                            }
                        }

                        .m-brand__tools {
                            display: $d_flex;
                            align-items: $align_items_center;
                            justify-content: $justify_content_end;

                            #m_aside_left_minimize_toggle {
                                span {
                                    background: $white;

                                    &::before {
                                        background: $white;
                                    }

                                    &::after {
                                        background: $white;
                                    }
                                }
                            }

                            .dashboard_view_btn {
                                display: $d_none;
                                background: none;
                                border: none;

                                >img {
                                    width: 100%;
                                    max-height: 20px;
                                    max-width: 4.45px;
                                    width: 100%;
                                }

                                @media (max-width: $larges) {
                                    display: $d_block;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }

                #m_header_nav {
                    box-shadow: 0px 1px 15px 1px $dark_grey;
                    background: $dark_color;
                    display: $d_flex;
                    justify-content: $justify_content_between;
                    align-items: $align_items_center;
                    padding: 15px;
                    margin-left: 255px;
                    width: calc(100% - 255px);

                    .hive_admin_view_sec {
                        display: $d_flex;
                        width: $w_100;
                        justify-content: $justify_content_between;
                        align-items: $align_items_center;
                        padding-left: 35px;

                        .hive_admin_view {
                            display: $d_flex;
                            align-items: $align_items_center;
                            padding-right: 15px;
                            position: relative;
                            // @media(max-width: 1590px) {
                            //     padding-bottom: 15px;
                            // }

                            @media (max-width: 992px) {
                                flex-wrap: $flex_wrap;
                            }

                            .hive_admin_view_box {
                                display: $d_flex;
                                align-items: $align_items_center;
                                // z-index: 7;

                                .hive_admin_text {
                                    margin: 0;
                                    font-size: $f14;
                                    line-height: $line_height_22;
                                    color: $white;
                                    min-width: 140px;
                                }

                                .multiSelectContainer {
                                    .searchWrapper {
                                        padding: 4px 4px 0 4px;
                                        min-height: 40px;
                                        min-width: 290px;
                                        max-height: 50vh;
                                        overflow-x: hidden;
                                        overflow-y: auto;

                                        .chip {
                                            background: $orange_col;
                                            white-space: normal;
                                        }

                                        >input {
                                            color: $white;
                                        }
                                    }

                                    .optionContainer {
                                        &::-webkit-scrollbar {
                                            width: 0;
                                        }

                                        >li {
                                            color: #fff;
                                            background: $dark_color;

                                            &:hover {
                                                background: $orange_col;
                                            }

                                            &.highlightOption {
                                                background: $dark_color;

                                                &:hover {
                                                    background: $orange_col;
                                                }
                                            }
                                        }

                                        .notFound {
                                            background: $dark_color;
                                            color: $white;
                                        }
                                    }
                                }
                            }

                            >.hive_admin_view_title {
                                font-size: $f24;
                                line-height: $line_height_30;
                                color: $white;
                                margin-bottom: 0;
                                padding: 3px 0;
                            }
                        }

                        .back_to_dash_sec_select {
                            display: $d_flex;
                            flex-wrap: wrap;
                            align-items: $align_items_center;
                            min-width: 695px;
                            justify-content: $justify_content_end;
                            position: relative;

                            @media(max-width:$laptops) {
                                justify-content: $justify_content_start;
                            }

                            @media (max-width: $desktop) {
                                min-width: unset;
                                // padding-bottom: 15px;

                                .back_to_dash {
                                    padding: 0;
                                }
                            }

                            .main_filter {
                                // position: relative;
                                // margin-right: 10px;
                                margin: 5px;

                                button {
                                    background-color: #424242;
                                    padding: 6px 15px;
                                    border-radius: 5px;
                                    display: flex;

                                    .filtericon {
                                        width: 22px;
                                    }

                                    &.select_btn {
                                        background-color: #ff9000;
                                    }

                                    .add_counter {
                                        height: 22px;
                                        width: 22px;
                                        border-radius: 50%;
                                        background-color: rgb(255, 0, 0);
                                        color: #fff;
                                        margin-left: 10px;
                                        align-items: center;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }

                                .filter_sec {
                                    align-items: baseline;
                                    background: #2b2a2a;
                                    box-shadow: 0 2px 8px 1px hsla(0, 0%, 100%, 0.23);
                                    // justify-content: center;
                                    margin: auto;
                                    padding: 20px 20px;
                                    position: absolute;
                                    left: 0px;
                                    // transform: translateX(-50%);
                                    z-index: 2;
                                    margin-top: 10px;
                                    align-items: center;
                                    flex-wrap: wrap;
                                    max-width: 732px;
                                    width: 100%;

                                    @media(max-width: $smallMobile) {
                                        max-height: 300px;
                                        overflow: scroll;
                                    }


                                    @media(max-width: $mobile) {
                                        justify-content: center;
                                        padding: 10px 10px;
                                        max-height: 370px;
                                        overflow: scroll;

                                    }

                                    @media(max-width: $large) {
                                        // left:-140px;
                                        // width: auto;
                                    }

                                    @media(max-width: $mobile_small) {
                                        left: 50%;
                                        transform: translateX(-50%);
                                        height: 400px;
                                        overflow: auto;

                                        // .sub_dropdown:not(:nth-child(3)){
                                        //     margin-bottom: 10px;
                                        //  }
                                    }

                                    .sub_dropdown {
                                        margin-bottom: 10px;
                                    }

                                    .close_icon {
                                        float: right;
                                        font-size: 2rem;
                                        font-weight: bold;
                                        line-height: 1;
                                        color: #ff9000;
                                        position: absolute;
                                        top: 2px;
                                        right: 3px;
                                        font-size: 25px;
                                        font-weight: bold;
                                        text-decoration: none;
                                    }

                                    .new_select_box_sec {
                                        &:last-child {
                                            padding-right: 0px !important;
                                        }

                                        // @media(max-width: $large_mobile) {
                                        //    margin: 10px 0;
                                        //  }
                                        .css-1xc3v61-indicatorContainer:first-child,
                                        .css-15lsz6c-indicatorContainer:first-child {
                                            display: none;
                                        }

                                        .css-3w2yfm-ValueContainer,
                                        .css-1dyz3mf {
                                            // min-height: 80px;
                                            overflow-y: auto;
                                            overflow-x: hidden;
                                            max-height: 80px;

                                            @media(max-width: $large_mobile) {
                                                max-height: 35px;
                                            }

                                            .css-1p3m7a8-multiValue {
                                                padding: 0 5px;
                                                background-color: #fa8c16;
                                                border-radius: 20px;

                                                .css-wsp0cs-MultiValueGeneric,
                                                .css-9jq23d {
                                                    color: #fff;
                                                    font-size: 13px;
                                                    line-height: 19px;

                                                    @media(max-width: $large_mobile) {
                                                        font-size: 12px;
                                                        line-height: 16px;
                                                    }
                                                }

                                                .css-12a83d4-MultiValueRemove,
                                                .css-v7duua {
                                                    display: none;
                                                }
                                            }

                                            &::-webkit-scrollbar {
                                                width: 7px;
                                            }

                                            &::-webkit-scrollbar-track {
                                                box-shadow: inset 0 0 5px grey;
                                                border-radius: 10px;
                                            }

                                            &::-webkit-scrollbar-thumb {
                                                background: $orange_color;
                                                border-radius: 10px;
                                            }

                                            &::-webkit-scrollbar-thumb:hover {
                                                background: $orange_color;
                                            }
                                        }

                                        .css-1nmdiq5-menu {
                                            // margin-left: -40px;
                                            width: 150px !important;
                                        }

                                        .css-d7l1ni-option,
                                        .css-tr4s17-option {
                                            background-color: #fa8c16 !important;
                                        }

                                        .css-tr4s17-option,
                                        .css-d7l1ni-option,
                                        .css-10wo9uf-option {
                                            display: flex;
                                            align-items: baseline;

                                            input[type="checkbox"] {
                                                margin-right: 10px;
                                            }

                                            label {
                                                word-break: break-all;
                                            }
                                        }

                                        &:first-child {
                                            .css-1nmdiq5-menu {
                                                margin-left: 0px;
                                            }
                                        }
                                    }

                                    .new_select_box_sec {
                                        cursor: $cursor_pointer;
                                        // padding-bottom: 10px;
                                        padding-right: 10px;
                                        max-width: 33.33%;
                                        width: $w_100;
                                        min-width: 217px;


                                        @media (max-width: $desktop) {
                                            max-width: 50%;
                                        }

                                        @media (max-width: $mobile) {
                                            min-width: 130px;
                                            max-width: $w_100;
                                            padding-right: 5px;
                                            max-width: 33.33%;
                                        }

                                        @media (max-width: $ipad) {
                                            max-width: 50%;
                                        }

                                        @media (max-width: $mobileLarge) {
                                            max-width: 100%;
                                        }

                                        .css-1nmdiq5-menu {
                                            // margin-left: -40px;
                                            width: 150px !important;
                                        }

                                        .css-13cymwt-control,
                                        .css-t3ipsp-control {
                                            // min-height: 34px;
                                            // height: 34px;
                                            cursor: $cursor_pointer;
                                            background-color: $tooltip_background;
                                            border: unset;
                                            box-shadow: unset;

                                            .css-1hb7zxy-IndicatorsContainer {
                                                .css-1u9des2-indicatorSeparator {
                                                    display: $d_none;
                                                }
                                            }

                                            .css-1fdsijx-ValueContainer {
                                                .css-1dimb5e-singleValue {
                                                    color: $white;
                                                }
                                            }

                                            .css-1hb7zxy-IndicatorsContainer {

                                                .css-1xc3v61-indicatorContainer,
                                                .css-15lsz6c-indicatorContainer {
                                                    color: $lighter_white;

                                                    &:hover {
                                                        color: $lighter_white;
                                                    }
                                                }
                                            }

                                            .css-hlgwow {
                                                >div {
                                                    color: $white;
                                                }
                                            }

                                            .css-1wy0on6 {
                                                >span {
                                                    display: $d_none;
                                                }
                                            }
                                        }

                                        >div:nth-child(4) {
                                            display: $d_block !important;
                                            background-color: $tooltip_background;
                                            color: $white;
                                            width: max-content;
                                            max-width: 200px;
                                            min-width: $w_100;
                                            z-index: 8;

                                            .css-1n6sfyn-MenuList {
                                                .css-tr4s17-option {
                                                    background: unset;
                                                    background-color: #fa8c16;
                                                }

                                                >div {
                                                    cursor: $cursor_pointer;

                                                    &.css-d7l1ni-option {
                                                        background: $orange_col;
                                                    }

                                                    &:hover {
                                                        background: $orange_col !important;

                                                        &[aria-disabled="true"] {
                                                            background: unset !important;
                                                        }
                                                    }
                                                }

                                                &::-webkit-scrollbar {
                                                    width: 7px;
                                                }

                                                &::-webkit-scrollbar-track {
                                                    box-shadow: inset 0 0 5px grey;
                                                    border-radius: 10px;
                                                }

                                                &::-webkit-scrollbar-thumb {
                                                    background: $orange_color;
                                                    border-radius: 10px;
                                                }

                                                &::-webkit-scrollbar-thumb:hover {
                                                    background: $orange_color;
                                                }
                                            }

                                            >div {
                                                &::-webkit-scrollbar {
                                                    width: 7px;
                                                }

                                                &::-webkit-scrollbar-track {
                                                    box-shadow: inset 0 0 5px grey;
                                                    border-radius: 10px;
                                                }

                                                &::-webkit-scrollbar-thumb {
                                                    background: $orange_color;
                                                    border-radius: 10px;
                                                }

                                                &::-webkit-scrollbar-thumb:hover {
                                                    background: $orange_color;
                                                }

                                                >div {
                                                    background-color: unset;

                                                    &:hover {
                                                        background-color: $orange_color;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }
                                        }

                                        >div {
                                            background: $border_color;
                                            border: unset;

                                            >div:nth-child(2) {
                                                >span {
                                                    display: $d_none;
                                                }
                                            }
                                        }


                                    }
                                }
                            }

                            .filter_sec {
                                @include filter;
                                // @media(max-width: $large_mobile) {
                                //     .select_box_sec{
                                //         &:not(:last-child){
                                //             padding-bottom: 10px;
                                //         }
                                //     }
                                // }
                                // &.TimeFrame_filter{
                                //     @media(max-width:332px){
                                //         padding-top: 10px;
                                //     }
                                // }
                            }

                            .approved_form_sec {
                                padding-bottom: 0;
                                // padding-right: 15px;
                                margin: 5px;

                                // @media(max-width: $mobileLarge) {
                                //     padding-bottom: 10px;
                                //     padding-top: 10px;
                                // }
                            }

                            .back_to_dash {
                                // padding: 3px 0 3px 15px;
                                margin: 5px;

                                .back_dash_btn {
                                    @include all_btn;
                                }
                            }
                        }

                        .main_show_practice_view {
                            @include filter;
                            display: unset;

                            .filter_sec {
                                align-items: center;

                                .select_box_sec {
                                    padding-right: 0px;
                                }
                            }

                            .back_to_dash {
                                // padding: 3px 0 3px 15px;
                                margin: 5px;

                                .back_dash_btn {
                                    @include all_btn;
                                }
                            }
                        }

                        // @media (max-width: $larges) {
                        //     .main_show_practice_view {
                        //          padding-bottom: 15px;
                        //     }
                        //  }

                        @media (max-width: 1590px) {
                            flex-wrap: $flex_wrap;
                        }

                        @media (max-width: $extraLarge) {
                            flex-wrap: $flex_wrap;

                            .hive_admin_view {
                                // padding-bottom: 15px;
                            }
                        }

                        @media (max-width: $larges) {
                            padding-left: 0;

                            .hive_admin_view {
                                flex-wrap: $flex_wrap;

                                .back_to_dash {
                                    padding: 15px 0;
                                }
                            }

                            .back_to_dash_sec_select {
                                min-width: unset;
                            }
                        }

                        @media (max-width: $ipad) {
                            flex-wrap: $flex_wrap;

                            .hive_admin_view {
                                padding-right: 0;
                            }

                            .back_to_dash {
                                padding: 0;
                            }

                            .back_to_dash_sec_select {
                                // padding-top: 15px;
                                flex-wrap: wrap;
                                // justify-content: space-between;

                                .back_to_dash {
                                    padding-left: unset;
                                    // padding-bottom: 10px;
                                    // padding-top: 10px;
                                }
                            }
                        }

                        @media (max-width: $mobileLarge) {
                            .hive_admin_view_box {
                                flex-wrap: $flex_wrap;

                                >p {
                                    padding-bottom: 5px;
                                }
                            }
                        }
                    }

                    @media (max-width: $larges) {
                        margin-left: 0;
                        width: $w_100;
                        position: $position_absolute;
                        top: -100%;
                        transition: 0.4s ease;
                        display: $d_none;

                        &.m_header_nav_active {
                            top: 100% !important;
                            height: unset;
                            display: $d_flex;
                        }
                    }
                }
            }
        }
    }

    .dash_toggle {
        margin: 0px 15px 0 0;
        display: flex;
        align-items: center;

        .toggle_btn_text {
            margin: 0;
            font-size: 14px;
            line-height: 30px;
            color: #ffffff;
            min-width: 70px;
        }

        .ctc_toggle {
            padding-right: 7px;
            padding-left: 9px;
        }

        .toggle-switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            margin-bottom: 0;
        }

        .toggle_btn_Open_Action_Item_Average {
            margin: 0;
            font-size: 14px;
            line-height: 30px;
            color: #ffffff;
            min-width: 70px;
            margin-right: 10px;
        }

        .toggle-switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .toggle-switch__slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            border-radius: 34px;
            transition: all 0.3s;
        }

        .ClickToCallToggle {
            padding: 10px;
        }


        .toggle-switch input:checked+.toggle-switch__slider {
            background-color: #e29631;
        }

        .toggle-switch__slider::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            border-radius: 50%;
            transition: all 0.3s;
        }

        .toggle-switch input:checked+.toggle-switch__slider::before {
            transform: translateX(26px);
        }

        @media(max-width: $large) {
            flex-direction: column;
        }

        .Business_Hours_Only_btn,
        .Remove_Whiskers_btn {

            /* Styles for elements with either class go here */
            @media (max-width: $ipad) {
                min-width: 148px;
            }

            @media (max-width: $mobile) {
                min-width: 88px;
            }
        }

        .Business_Hours_Only_toggle_Switch,
        .Business_Remove_Whiskers_toggle_Switch {
            @media (max-width: $ipad) {
                margin-right: 31px;
            }

            @media (max-width: $mobile) {
                margin-right: 31px;
            }
        }
    }

    .dashboard_section {
        background: $black;
        position: $position_relative;
        overflow: hidden;

        .m-aside-left {
            position: $position_fixed;
            bottom: 0;
            top: 70px;
            transition: none;
            width: $w_255;
            background: $dark_color;
            border-right: 1px solid $border_color;
            z-index: 10 !important;
            transition: 1s ease-in-out;

            @media (max-width: 992px) {
                top: 75px;
                left: -100%;
                border-right: unset;

                &.m-aside-left_active {
                    left: 0;
                }
            }

            .m-aside-menu {
                .m-menu__nav {
                    padding: 0px !important;
                    margin: 0;
                    margin-bottom: 1px;

                    >li {
                        background: unset;

                        &.active {
                            background: $orange_col;
                        }

                        >a {
                            >span {
                                font-size: $f14;
                                line-height: $line_height_22;
                            }
                        }

                        .alerts_counter {
                            height: 22px;
                            width: 22px;
                            border-radius: 50%;
                            background-color: rgb(255, 0, 0);
                            color: #fff;
                            margin-left: -141px;
                            /* margin-top: -1px; */
                            align-items: center;
                            display: flex;
                            justify-content: center;
                        }
                    }
                }

                .mCustomScrollBox {
                    position: $position_relative;
                    overflow: hidden;
                    height: $h_100;
                    max-width: $w_100;
                    outline: none;
                    direction: ltr;
                    max-height: none;
                }
            }
        }

        .row-dashboard {
            padding: 26px 0;
            width: $w_100;
            min-height: calc(100vh + 170px);

            .dashboard_chart_sec {
                display: $d_flex;
                justify-content: $justify_content_between;
                flex-wrap: $flex_wrap;

                .dashboard_chart {
                    display: $d_flex;
                    flex-direction: $flex_direction_column;
                    // padding: 16px 0 0 0;
                    // width: 49.29%;
                    background: $dark_color;
                    margin-bottom: 24px;
                    position: relative;

                    &.heat_chart_sec {
                        .main_filter_sec {
                            @media (max-width: $ipad) {
                                display: none;
                            }
                        }
                    }

                    .responsive_dropdown {
                        display: none;

                        @media (max-width: $ipad) {
                            display: block;
                        }

                        button {
                            background-color: transparent;
                        }

                        .overlay {
                            .filter_sec {
                                align-items: baseline;
                                background: #2b2a2a;
                                box-shadow: 0 2px 8px 1px hsla(0, 0%, 100%, 0.23);
                                justify-content: center;
                                margin: auto;
                                padding: 20px 10px;
                                position: absolute;
                                top: 62px;
                                transform: translateX(-50%);
                                transition: all 5s ease-in-out;
                                width: 100%;
                                left: 50%;
                                z-index: 2;

                                @media (max-width: $mobile) {
                                    // justify-content: space-between;
                                    padding: 20px 20px;
                                }

                                .close_icon {
                                    float: right;
                                    font-size: 2rem;
                                    font-weight: bold;
                                    line-height: 1;
                                    color: #ff9000;
                                    position: absolute;
                                    top: 2px;
                                    right: 3px;
                                    font-size: 25px;
                                    font-weight: bold;
                                    text-decoration: none;
                                }

                                .select_box_sec {
                                    @media (max-width: $mobile) {
                                        padding-right: 10px !important;
                                        min-width: 80px !important;
                                        max-width: 112px;
                                    }

                                    &:last-child {
                                        padding-right: 0px !important;
                                    }

                                    .css-13cymwt-control {
                                        // border: 1px solid #939393 !important;
                                    }



                                    &:first-child {
                                        .css-1nmdiq5-menu {
                                            margin-left: 0px;
                                        }
                                    }
                                }

                                .custom_form_credentials {
                                    background: #FA8C16;
                                    border: 1px solid #FA8C16;
                                    padding: 7px 7px;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: #ffffff;
                                    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                                    border-radius: 2px;
                                    transition: 0.4s ease;
                                    // min-width: 170px;
                                    width: 236px;
                                    cursor: pointer;
                                    margin-right: 10px;

                                    @media(max-width: $mobile) {
                                        width: 216px;
                                        font-size: 12px;
                                        line-height: 20px;
                                        padding: 6px 7px;
                                        margin-bottom: 12px
                                    }
                                }
                            }
                        }

                        .Top_Performing_Ads_chart_responsive_dropdown {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .Top_Performing_Ads_chart_select_box_responsive_dropdown {
                                padding: 10px;
                            }
                        }
                    }

                    // .responsive_dropdown_small_chart {
                    //     display: none;

                    //     @media (max-width: 2000px) {
                    //         display: block;
                    //     }

                    //     button {
                    //         background-color: transparent;
                    //     }

                    //     .overlay {
                    //         .filter_sec {
                    //             align-items: baseline;
                    //             background: #2b2a2a;
                    //             box-shadow: 0 2px 8px 1px hsla(0, 0%, 100%, 0.23);
                    //             justify-content: center;
                    //             margin: auto;
                    //             padding: 20px 10px;
                    //             position: absolute;
                    //             top: 70px;
                    //             transform: translateX(-50%);
                    //             transition: all 5s ease-in-out;
                    //             width: 100%;
                    //             left: 50%;
                    //             z-index: 2;

                    //             @media (max-width: $mobile) {
                    //                 // justify-content: space-between;
                    //                 padding: 20px 20px;
                    //             }

                    //             .close_icon {
                    //                 float: right;
                    //                 font-size: 2rem;
                    //                 font-weight: bold;
                    //                 line-height: 1;
                    //                 color: #ff9000;
                    //                 position: absolute;
                    //                 top: 2px;
                    //                 right: 3px;
                    //                 font-size: 25px;
                    //                 font-weight: bold;
                    //                 text-decoration: none;
                    //             }

                    //             .select_box_sec {
                    //                 @media (max-width: $mobile) {
                    //                     padding-right: 10px !important;
                    //                     min-width: 80px !important;
                    //                     max-width: 112px;
                    //                 }

                    //                 &:last-child {
                    //                     padding-right: 0px !important;
                    //                 }

                    //                 .css-13cymwt-control {
                    //                     // border: 1px solid #939393 !important;
                    //                 }



                    //                 &:first-child {
                    //                     .css-1nmdiq5-menu {
                    //                         margin-left: 0px;
                    //                     }
                    //                 }
                    //             }

                    //             .custom_form_credentials {
                    //                 background: #FA8C16;
                    //                 border: 1px solid #FA8C16;
                    //                 padding: 7px 7px;
                    //                 font-size: 14px;
                    //                 line-height: 22px;
                    //                 color: #ffffff;
                    //                 box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                    //                 border-radius: 2px;
                    //                 transition: 0.4s ease;
                    //                 // min-width: 170px;
                    //                 width: 236px;
                    //                 cursor: pointer;
                    //                 margin-right: 10px;

                    //                 @media(max-width: $mobile) {
                    //                     width: 216px;
                    //                     font-size: 12px;
                    //                     line-height: 20px;
                    //                     padding: 6px 7px;
                    //                     margin-bottom: 12px
                    //                 }
                    //             }

                    //             .Google_Customer_ID_Show_Main {
                    //                 color: #ffffff;
                    //                 font-family: "Poppins-Medium";
                    //                 font-size: 16px;
                    //                 line-height: 24px;
                    //                 margin-top: 10px;
                    //             }
                    //         }
                    //     }
                    // }




                    .chart_title_sec {
                        display: $d_flex;
                        width: $w_100;
                        justify-content: $justify_content_between;
                        align-items: $align_items_center;
                        // flex-wrap: $flex_wrap;/
                        border-bottom: 1px solid $graph_line_col;
                        padding-bottom: 5px;
                        margin-bottom: 10px;
                        padding-left: 20px;
                        padding-right: 20px;
                        position: relative;

                        @media (max-width: 1110px) {
                            padding-left: 10px;
                        }

                        .chart_heading_sec {
                            display: $d_flex;
                            align-items: $align_items_center;
                            padding: 15px 0;

                            // flex-wrap: $flex_wrap;
                            // flex-grow: $flex_grow_1;
                            .chart_title_icons {
                                display: $d_flex;
                                align-items: $align_items_center;
                                padding-right: 11px;

                                @media (max-width: 1110px) {
                                    padding-right: 8px;
                                }

                                @media (max-width: $mobile) {
                                    padding-right: 0px;
                                }

                                .calender_logo {
                                    // -webkit-mask: url(../image/confirm-showed-appt.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    // background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .calender_logo_gauge_chart {
                                    -webkit-mask: url(../image/calendar-percent.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .calender_logo_showed_appointment {
                                    -webkit-mask: url(../image/calendar-check.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .approved_form_logo {
                                    -webkit-mask: url(../image/clipboard_checked_icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .insurance_check_tile_logo {
                                    -webkit-mask: url(../image/insurance_check_tile_icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .facebook_funnel_logo {
                                    -webkit-mask: url(../image/facebook_logo_icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .google_ads_funnel_logo {
                                    -webkit-mask: url(../image/Google_Ads_logo.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .Top_Performing_Ad_Logo {
                                    -webkit-mask: url(../image/Top_Performing_Ads_chart_Icon.png) no-repeat center/contain;
                                    width: 40px;
                                    height: 40px;
                                    top: 2px;
                                    display: $d_block;
                                    background-color: $white;

                                    // @media (max-width: $mobile) {
                                    //     height: 25px;
                                    //     width: 25px;
                                    // }
                                }

                                .hand_click_tile_logo {
                                    -webkit-mask: url(../image/hand_click_icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .phone_flow_logo {
                                    -webkit-mask: url(../image/phone-icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .approved_form_logo_blank {
                                    -webkit-mask: url(../image/clipboard_empty_icon.png) no-repeat center/contain;
                                    width: 30px;
                                    height: 30px;
                                    display: $d_block;
                                    background-color: $white;

                                    @media (max-width: $mobile) {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                >span {
                                    >img {
                                        height: 30px;
                                        width: auto;
                                        display: $d_block;

                                        @media (max-width: $mobile) {
                                            height: 25px;
                                        }
                                    }
                                }
                            }

                            .tooltip_title {
                                padding-right: 10px;
                                cursor: pointer;

                                &.add_pointer {
                                    cursor: default;
                                }

                                @media (max-width: 1110px) {
                                    padding-right: 5px;
                                }

                                &:last-child {
                                    padding-right: unset;
                                }

                                .tooltip_title_sec {
                                    position: $position_relative;
                                    top: 7px;

                                    .tooltip_title_hidden {
                                        display: $d_none;
                                        background: $white;
                                        color: $black;
                                        padding: 3px 10px;
                                        position: $position_absolute;
                                        top: 130%;
                                        width: max-content;
                                        max-width: 300px;
                                        font-family: "Poppins-Medium";
                                        font-size: $f13;
                                        line-height: $line_height_15;
                                        left: 0%;
                                        transform: unset;
                                        z-index: 3;

                                        &::before {
                                            content: "";
                                            position: $position_absolute;
                                            width: 0;
                                            height: 0;
                                            border-left: 5px solid transparent;
                                            border-right: 5px solid transparent;
                                            border-bottom: 10px solid $white;
                                            top: -10px;
                                            left: 10px;
                                            transform: unset;
                                            z-index: -1;
                                        }
                                    }
                                }

                                &:hover {
                                    .tooltip_title_sec {
                                        &::before {
                                            display: $d_block;
                                        }

                                        .tooltip_title_hidden {
                                            display: $d_block;

                                            // @include media_query(Medium) {
                                            //     display: $d_block;
                                            // }
                                        }
                                    }
                                }

                                @media (max-width: $mobile_small) {
                                    padding-right: 5px;

                                    .tooltip_title_sec {
                                        .tooltip_title_hidden {
                                            max-width: 225px;
                                        }
                                    }
                                }
                            }

                            // .btn_add_website_traffic {
                            //     background: #FA8C16;
                            //     border: 1px solid #FA8C16;
                            //     padding: 7px 7px;
                            //     font-size: 14px;
                            //     line-height: 22px;
                            //     color: #ffffff;
                            //     box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                            //     border-radius: 2px;
                            //     transition: 0.4s ease;
                            //     // min-width: 170px;
                            //     width: 236px;
                            //     cursor: pointer;
                            //     margin-right: 10px;

                            //     @media(max-width: $mobile) {
                            //         width: 216px;
                            //         font-size: 12px;
                            //         line-height: 20px;
                            //         padding: 6px 7px;
                            //     }
                            // }

                            .custom_form_credentials_main_filter_sec {
                                @media (max-width: $ipad) {
                                    display: none;
                                }
                            }

                            h2 {
                                // display: $d_flex;
                                margin-bottom: 0;
                                padding-right: 10px;

                                @media (max-width: 1110px) {
                                    padding-right: 4px;
                                }

                                span {
                                    color: $white;
                                    font-family: "Poppins-Medium";
                                    font-size: $f16;
                                    line-height: $line_height_24;
                                }

                                .title_text {
                                    &.add_dots {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        word-break: break-all;
                                        overflow: hidden;

                                        // @include media_query(Medium) {
                                        //     display: -webkit-box;
                                        // }
                                    }
                                }

                                .newChart_name {
                                    display: flex;

                                    &.add_dots {
                                        display: -webkit-box;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        word-break: break-all;
                                        overflow: hidden;

                                        // @include media_query(Medium) {
                                        //     display: -webkit-box;
                                        // }
                                    }
                                }

                                // @media(max-width:$desktop_large) {
                                //     display: $d_none;
                                // }
                                // @media(max-width: $desktop_view){
                                //     display: $d_flex;
                                // }
                                // @media(max-width: $large_mobile){
                                //     display: $d_none;
                                // }
                            }

                            @media (max-width: $ipad) {
                                padding-bottom: 0;

                                h2 {
                                    // padding-bottom: 10px;
                                }

                                .chart_title_icons {
                                    padding-bottom: 10px;
                                }
                            }
                        }

                        .add_website_traffic_btn_main_right_all_btn {
                            display: flex;

                            .add_website_traffic_Google_Analytices_btn_main {

                                @media (max-width: $ipad) {
                                    display: none;
                                }

                            }

                            .btn_add_website_traffic {
                                background: #FA8C16;
                                border: 1px solid #FA8C16;
                                padding: 7px 7px;
                                font-size: 14px;
                                line-height: 22px;
                                color: #ffffff;
                                box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                                border-radius: 2px;
                                transition: 0.4s ease;
                                // min-width: 170px;
                                width: 236px;
                                cursor: pointer;
                                margin-right: 10px;

                                @media(max-width: $mobile) {
                                    width: 134px;
                                    font-size: 12px;
                                    line-height: 20px;
                                    padding: 6px 0px;
                                }

                                @media (max-width:389px) {
                                    width: 125px;
                                    padding: 4px px 4px;
                                }
                            }

                        }

                        .dashboard_view_btn {
                            background: none;
                            border: none;

                            >img {
                                height: 21px;
                            }

                            &:focus {
                                outline: none;
                            }
                        }

                        .dash_toggle {
                            margin: 0px 12px 0 0;
                            display: flex;

                            @media (max-width: $mobileLarge) {
                                margin: 0px 2px 0 0;
                            }

                            .toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 86px;
                            }

                            .toggle-switch {
                                position: relative;
                                display: inline-block;
                                width: 60px;
                                height: 34px;

                                // @media(max-width: $large) {
                                //     margin-right: 104px;
                                // }
                            }

                            .toggle-switch input {
                                opacity: 0;
                                width: 0;
                                height: 0;
                            }

                            .toggle-switch__slider {
                                position: absolute;
                                cursor: pointer;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: #ccc;
                                border-radius: 34px;
                                transition: all 0.3s;
                            }

                            .toggle-switch input:checked+.toggle-switch__slider {
                                background-color: #e29631;
                            }

                            .toggle-switch__slider::before {
                                position: absolute;
                                height: 26px;
                                width: 26px;
                                left: 4px;
                                bottom: 4px;
                                background-color: white;
                                border-radius: 50%;
                                transition: all 0.3s;
                                content: "Σ";
                                text-align: center;
                                padding-top: 3px;
                            }

                            .toggle-switch input:checked+.toggle-switch__slider::before {
                                transform: translateX(26px);
                            }

                            .businesShoursToggle {
                                max-width: 224px !important;


                                @media (max-width: $mobile_small) {
                                    max-width: 240px;
                                    left: 3px !important;
                                }
                            }

                            .tooltip_title_sec_bubble {
                                position: $position_relative;
                                right: 120px;
                                bottom: -27px;
                                opacity: 0;

                                @media (max-width: $mobile) {
                                    right: 142px;
                                }


                                .tooltip_title_hidden_bubble {
                                    display: $d_none;
                                    background: $white;
                                    color: $black;
                                    padding: 3px 10px;
                                    position: $position_absolute;
                                    top: 130%;
                                    width: max-content;
                                    max-width: 300px;
                                    font-family: "Poppins-Medium";
                                    font-size: $f13;
                                    line-height: $line_height_15;
                                    left: 0%;
                                    transform: unset;
                                    z-index: 2;

                                    @media (max-width: $mobile_small) {
                                        max-width: 240px;
                                        left: 40px;
                                    }

                                    &::before {
                                        content: "";
                                        position: $position_absolute;
                                        width: 0;
                                        height: 0;
                                        border-left: 5px solid transparent;
                                        border-right: 5px solid transparent;
                                        border-bottom: 10px solid $white;
                                        top: -10px;
                                        left: 85px;
                                        transform: unset;
                                        z-index: -1;

                                        @media (max-width: $mobile_small) {
                                            left: 45px;
                                        }

                                        @media (max-width: $mobile) {
                                            left: 70px;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .tooltip_title_sec_bubble {
                                    opacity: 1;
                                    transition: opacity 0s linear 1s;
                                }

                                .tooltip_title_sec_bubble {
                                    &::before {
                                        display: $d_block;
                                    }

                                    .tooltip_title_hidden_bubble {
                                        display: $d_block;
                                    }
                                }
                            }
                        }

                        .Tooltipbold {
                            font-weight: bold;
                        }

                        .tooltip_title_bubble {
                            .tooltip_bubble {
                                background-color: #e29631;
                                height: 23px;
                                width: 23px;
                                text-align: $text_center;
                                border-radius: 50%;
                                cursor: $cursor_pointer;
                                color: $white;
                                font-size: larger;
                            }

                            padding-right: 10px;
                            cursor: $cursor_pointer;

                            &:last-child {
                                padding-right: unset;
                            }

                            .tooltip_title_sec_bubble {
                                position: $position_relative;
                                top: 7px;
                                right: 4px;

                                .tooltip_title_hidden_bubble {
                                    display: $d_none;
                                    background: $white;
                                    color: $black;
                                    padding: 3px 10px;
                                    position: $position_absolute;
                                    top: 130%;
                                    width: max-content;
                                    max-width: 300px;
                                    font-family: "Poppins-Medium";
                                    font-size: $f13;
                                    line-height: $line_height_15;
                                    left: 0%;
                                    transform: unset;
                                    z-index: 2;

                                    &::before {
                                        content: "";
                                        position: $position_absolute;
                                        width: 0;
                                        height: 0;
                                        border-left: 5px solid transparent;
                                        border-right: 5px solid transparent;
                                        border-bottom: 10px solid $white;
                                        top: -10px;
                                        left: 10px;
                                        transform: unset;
                                        z-index: -1;
                                    }

                                    &.mobile_show_tooltip {
                                        max-width: 900px;

                                        @media (max-width: $larges) {
                                            max-width: 600px;
                                        }

                                        @media (max-width: $small_ipad) {
                                            max-width: 300px;
                                            height: 400px;
                                            overflow-y: auto;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .tooltip_title_sec_bubble {
                                    &::before {
                                        display: $d_block;
                                    }

                                    .tooltip_title_hidden_bubble {
                                        display: $d_block;
                                    }
                                }
                            }

                            @media (max-width: $mobile_small) {
                                padding-right: 5px;

                                .tooltip_title_sec_bubble {
                                    .tooltip_title_hidden_bubble {
                                        max-width: 225px;

                                        &.mobile_show_tooltip {
                                            max-width: 280px;
                                            top: 10px;
                                            left: -110%;
                                            height: 400px;
                                            overflow-y: auto;

                                        }

                                    }
                                }
                            }
                        }

                        // @media (max-width: $ipad) {
                        // flex-wrap: $flex_wrap;
                        // }

                        @media (max-width: $mobile) {
                            padding-left: 5px;
                            // padding-right: 10px;
                        }

                        @media (max-width: $smallMobile) {
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                    }

                    .filter_sec {
                        display: $d_flex;
                        align-items: $align_items_center;
                        // max-width: 147px;
                        // width: 100%;
                        justify-content: end;

                        .select_box_sec {
                            cursor: $cursor_pointer;
                            // padding-bottom: 10px;
                            padding-right: 10px;
                            max-width: 130px;
                            width: $w_100;
                            min-width: 130px;

                            @media (max-width: $mobile) {
                                min-width: 96px;
                                max-width: 96px;
                                padding-right: 5px;
                            }

                            @media (max-width: $mobileSmall) {
                                min-width: 86px;
                                max-width: 86px;
                                padding-right: 0px;
                            }

                            .css-1nmdiq5-menu {
                                margin-left: -40px;
                                width: 150px !important;
                            }

                            .css-13cymwt-control,
                            .css-t3ipsp-control {
                                min-height: 34px;
                                height: 34px;
                                cursor: $cursor_pointer;
                                background-color: $tooltip_background;
                                border: unset;
                                box-shadow: unset;

                                .css-1hb7zxy-IndicatorsContainer {
                                    .css-1u9des2-indicatorSeparator {
                                        display: $d_none;
                                    }
                                }

                                .css-1fdsijx-ValueContainer {
                                    .css-1dimb5e-singleValue {
                                        color: $white;
                                    }
                                }

                                .css-1hb7zxy-IndicatorsContainer {

                                    .css-1xc3v61-indicatorContainer,
                                    .css-15lsz6c-indicatorContainer {
                                        color: $lighter_white;

                                        &:hover {
                                            color: $lighter_white;
                                        }
                                    }
                                }

                                .css-hlgwow {
                                    >div {
                                        color: $white;
                                    }
                                }

                                .css-1wy0on6 {
                                    >span {
                                        display: $d_none;
                                    }
                                }
                            }

                            >div:nth-child(4) {
                                display: $d_block !important;
                                background-color: $tooltip_background;
                                color: $white;
                                width: max-content;
                                max-width: 200px;
                                min-width: $w_100;
                                z-index: 8;

                                .css-1n6sfyn-MenuList {
                                    .css-tr4s17-option {
                                        background: unset;
                                        background-color: unset;
                                    }

                                    >div {
                                        cursor: $cursor_pointer;

                                        &.css-d7l1ni-option {
                                            background: $orange_col;
                                        }

                                        &:hover {
                                            background: $orange_col !important;

                                            &[aria-disabled="true"] {
                                                background: unset !important;
                                            }
                                        }
                                    }

                                    &::-webkit-scrollbar {
                                        width: 7px;
                                    }

                                    &::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 5px grey;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        background: $orange_color;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb:hover {
                                        background: $orange_color;
                                    }
                                }

                                >div {
                                    &::-webkit-scrollbar {
                                        width: 7px;
                                    }

                                    &::-webkit-scrollbar-track {
                                        box-shadow: inset 0 0 5px grey;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        background: $orange_color;
                                        border-radius: 10px;
                                    }

                                    &::-webkit-scrollbar-thumb:hover {
                                        background: $orange_color;
                                    }

                                    >div {
                                        background-color: unset;

                                        &:hover {
                                            background-color: $orange_color;
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            >div {
                                background: $border_color;
                                border: unset;

                                >div:nth-child(2) {
                                    >span {
                                        display: $d_none;
                                    }
                                }
                            }
                        }

                        .main_filter_icon {
                            .tooltip_title_hidden_bubble {
                                right: 0% !important;
                                left: auto !important;

                                &::before {
                                    right: 20px !important;
                                    left: auto !important;
                                }

                                ul {
                                    margin-bottom: 0;
                                }
                            }

                            .sub_filter {
                                background-color: #ff9000;
                                padding: 6px 10px;
                                border-radius: 5px;
                                display: flex;

                                @media (max-width: $mobileLarge) {
                                    padding: 4px 6px;
                                }

                                img {
                                    width: 26px;
                                    height: 26px;
                                    display: block;

                                    @media (max-width: $mobileLarge) {
                                        width: 22px;
                                        height: 22px;
                                    }
                                }
                            }
                        }

                        // @media(max-width: $mobile) {
                        //     flex-wrap: $flex_wrap;
                        // }
                        .custom_form_credentials_main_filter_sec {
                            @media (max-width: $ipad) {
                                display: none;
                            }
                        }

                        .custom_form_credentials,
                        .btn_add_facebook_adwords,
                        .btn_add_google_adwords {
                            background: #FA8C16;
                            border: 1px solid #FA8C16;
                            padding: 7px 7px;
                            font-size: 14px;
                            line-height: 22px;
                            color: #ffffff;
                            box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
                            border-radius: 2px;
                            transition: 0.4s ease;
                            // min-width: 170px;
                            width: 236px;
                            cursor: pointer;
                            margin-right: 10px;

                            @media(max-width: $mobile) {
                                width: 216px;
                                font-size: 12px;
                                line-height: 20px;
                                padding: 6px 7px;
                                margin-bottom: 12px
                            }
                        }
                    }

                    .chart_section {
                        padding-left: 20px;
                        padding-right: 20px;
                        z-index: 1;

                        .multiLine_chart_span {
                            color: white;
                            margin: 0 10px;
                            display: flex;

                            .s_hive_logo {
                                width: 35px;
                                height: 35px;
                                margin: 4px 10px 0 0;
                            }

                            .FollowUpLogo {
                                width: 35px;
                                height: 38px;
                                margin: -1px 10px 0 0;
                            }
                        }

                        .multiLine_chart_span_Alert {
                            .s_hive_logo {
                                width: 35px;
                                height: 35px;
                                margin: -4px 13px 0 0;
                            }
                        }


                        .business_hours_span {
                            color: white;
                            margin-left: 108px;

                            @media (max-width: $small_ipad) {
                                margin-left: 0;
                                text-align: center;
                            }

                            @media (max-width: $mobile) {
                                text-align: left;
                            }

                            >span {
                                margin-left: 10px;

                                &:not(:last-child) {
                                    @media (max-width: $mobile) {
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }

                        .chart_logo_section {
                            display: flex;
                            justify-content: flex-end;

                            .insurance_check_tile_logo {
                                -webkit-mask: url(../image/insurance_check_tile_icon.png) no-repeat center/contain;
                                width: 30px;
                                height: 30px;
                                background-color: $white;
                                position: absolute;

                                @media (max-width: $mobile) {
                                    height: 25px;
                                    width: 25px;
                                }
                            }

                            .facebook_funnel_logo {
                                -webkit-mask: url(../image/facebook_logo_icon.png) no-repeat center/contain;
                                width: 30px;
                                height: 30px;
                                background-color: $white;
                                position: absolute;

                                @media (max-width: $mobile) {
                                    height: 25px;
                                    width: 25px;
                                }
                            }

                            .hand_click_tile_logo {
                                -webkit-mask: url(../image/hand_click_icon.png) no-repeat center/contain;
                                width: 30px;
                                height: 30px;
                                background-color: $white;
                                position: absolute;

                                @media (max-width: $mobile) {
                                    height: 25px;
                                    width: 25px;
                                }
                            }

                            .calender_logo_showed_appointment {
                                -webkit-mask: url(../image/calendar-check.png) no-repeat center/contain;
                                width: 30px;
                                height: 30px;
                                background-color: $white;
                                position: absolute;

                                @media (max-width: $mobile) {
                                    height: 25px;
                                    width: 25px;
                                }
                            }
                        }

                        .chart_sec {
                            width: $w_100;
                            min-height: 440px;

                            .tooltip_sec {
                                // min-width: 100%;
                                // width: $w_100;
                                display: $d_flex;
                                flex-grow: 1;
                                white-space: nowrap;
                                // white-space: break-spaces;
                                // padding: 0 15px 0 0;

                                @media (max-width: $desktop_view) {
                                    white-space: break-spaces;
                                    flex-wrap: $flex_wrap;
                                    // display: inline-block;
                                }

                                .tooltip_part {
                                    display: $d_flex;
                                    justify-content: $justify_content_between;
                                    // flex-wrap: $flex_wrap;
                                    width: $w_100;

                                    .tooltip_title_sec {
                                        color: $black;
                                        font-family: "poppins-Bold";
                                        padding: 0;
                                        margin: 0;
                                        line-height: $line_height_100_p;

                                        @media (max-width: 768px) {
                                            font-size: 11px;
                                        }

                                        @media (max-width: $desktop_view) {
                                            line-height: $line_height_140_p;
                                        }
                                    }

                                    .tooltip_date_sec {
                                        display: $d_flex;
                                        align-items: $align_items_center;

                                        @media (max-width: 768px) {
                                            font-size: 10px;
                                        }

                                        .tooltip_date_box {
                                            width: 13px;
                                            height: 13px;
                                            border-radius: $border_radius_3;
                                            background: $orange_color;
                                        }

                                        .form_fill_label {
                                            color: $black !important;
                                            font-family: "poppins-Medium";
                                            line-height: $line_height_80_p;

                                            @media (max-width: $desktop_view) {
                                                line-height: $line_height_140_p;
                                            }
                                        }

                                        .tooltip_date {
                                            padding-left: 7px;
                                            color: $black;
                                            line-height: $line_height_80_p;
                                        }
                                    }

                                    .tooltip_compare_sec {
                                        display: $d_flex;
                                        align-items: $align_items_baseline;
                                    }

                                    .tooltip_year {
                                        font-family: "Poppins-SemiBold";
                                        margin: 0;
                                        padding: 0;
                                    }

                                    padding-right: 5px;

                                    &:last-child {
                                        padding-right: unset;
                                    }
                                }

                                >img {
                                    width: 25px;
                                    height: 25px;
                                }
                            }

                            .main_chart_text {
                                p {
                                    margin-bottom: 0 !important;
                                }
                            }

                            @media (max-width: $desktop_view) and (min-width: $laptops) {
                                @include chart_text;

                                .main_chart_text {
                                    .chart_text {
                                        font-size: 12px;
                                    }
                                }
                            }

                            @media (max-width: $mobileLarge) {
                                @include chart_text;
                            }

                            @media (max-width: $mobileSmall) {
                                .main_chart_text {
                                    // &.only_mob_text {
                                    font-size: 10px;
                                    max-width: 120px;
                                    width: 100%;
                                    // }
                                }
                            }

                            @media (max-width: $mobileLarge) {
                                min-height: 550px;
                            }
                        }

                        .chart_sec_showed_and_upcoming_consult_report_chart {
                            min-height: 485px;

                            @media (max-width: 1358px) {
                                min-height: 503px;
                            }
                        }

                        .chart_loader_sec {
                            position: $position_relative;
                            width: $w_100;
                            height: $h_100;
                            color: $white;
                            left: 0;
                            top: 0;
                            margin-bottom: 0;
                            vertical-align: middle;
                            min-height: 440px;
                            display: $d_flex;
                            justify-content: $justify_content_center;
                            align-items: $align_items_center;
                            line-height: $line_height_140_p;
                            word-break: break-all;

                            @media (max-width: $mobileLarge) {
                                min-height: 540px;
                            }
                        }

                        .Clicktocall_flag_error {
                            color: white;
                            display: flex;
                            flex-wrap: wrap;
                            word-break: break-all;
                        }
                    }

                    .select_boxes {
                        display: $d_flex;
                        flex-wrap: $flex_wrap;
                        justify-content: $text_center;

                        .select_box_sec {
                            padding-right: 13px;

                            .select_box_section {
                                color: $orange_color;
                                display: $d_block;
                                position: $position_relative;
                                padding-left: 23px;
                                margin-bottom: 12px;
                                cursor: $cursor_pointer;
                                font-size: $f22;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                font-size: $f16;
                                opacity: $opacity_0_5;

                                input {
                                    position: $position_absolute;
                                    opacity: $opacity_0_5;
                                    cursor: $cursor_pointer;
                                    height: 0;
                                    width: 0;
                                }

                                .checkmark {
                                    position: $position_absolute;
                                    top: 50%;
                                    left: 0;
                                    height: 17px;
                                    width: 17px;
                                    background-color: $orange_color;
                                    border-radius: $border_radius_3;
                                    transform: translateY(-50%);
                                    opacity: $opacity_0_5;

                                    &:after {
                                        content: "";
                                        position: $position_absolute;
                                        display: $d_none;
                                        left: 6px;
                                        top: 3px;
                                        width: 5px;
                                        height: 10px;
                                        border: solid $black;
                                        border-width: 0 2px 2px 0;
                                        transform: rotate(45deg);
                                    }
                                }

                                input:checked~.checkmark {
                                    opacity: 1;
                                }

                                input:checked~.checkmark:after {
                                    display: $d_block;
                                }
                            }

                            .select_box_section:has(> input:checked) {
                                opacity: 1;
                            }

                            /* Style the checkmark/indicator */
                        }
                    }

                    // .d_block {
                    //     display: block;
                    // }

                    >div:nth-child(2) {
                        >div {
                            width: $w_100 !important;
                        }
                    }

                    .m-main_option {
                        display: none;
                        box-shadow: 0px 0px 13px 0px rgba(177, 177, 177, 0.2);
                        background: $dark_color;
                        justify-content: $justify_content_between;
                        align-items: $align_items_center;
                        // padding: 10px 15px;
                        position: absolute;
                        z-index: 2;
                        top: 48px;
                        right: 20px;
                        cursor: $cursor_pointer;

                        &.m-show_option {
                            display: block;
                        }

                        ul {
                            padding: 0;
                            margin: 0;

                            .sub_option {
                                list-style: none;
                                margin: 0;
                                padding: 10px 15px;
                                font-size: 12px;
                                line-height: 18px;
                                color: $white;
                                min-width: 140px;

                                &:hover {
                                    background: #fa8c16;
                                }
                            }
                        }
                    }

                    &.dashboard_chart_full_w {
                        width: $w_100;
                    }

                    &.multiline_chart {
                        z-index: 2;
                        width: var(--approved_form_fill_to_showed_consult_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        @media (max-width: $desktop_view) {
                            .tooltip_sec {
                                position: unset;
                                height: 100%;
                            }
                        }

                        // @media(max-width: $desktop) and (min-width: $laptops){
                        //     .chart_title_icons{
                        //         .title_text{
                        //             &.add_dots{
                        //                 max-width: 100px;
                        //                 width: 100%;
                        //             }
                        //         }
                        //     }
                        // }
                        @media (max-width: $mobile_small) {
                            .filter_sec {
                                flex-wrap: unset;
                            }

                            .chart_title_icons {
                                .title_text {
                                    &.add_dots {
                                        // max-width: 100px;
                                        width: 100%;
                                    }
                                }

                                // .newChart_name {

                                //     // max-width: 100px;
                                //     width: 100%;
                                // }
                            }
                        }
                    }

                    &.showed_and_upcoming_consult_report_chart {
                        width: var(--showed_and_upcoming_consult_report_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        @media (max-width: $desktop_view) {
                            .tooltip_sec {
                                position: unset;
                                // min-height: 16px;
                                height: 100%;
                            }
                        }
                    }

                    &.website_conversion_google_analytics_chart {
                        width: var(--website_conversion_google_analytics_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.click_to_call_website_conversion_chart {
                        width: var(--click_to_call_website_conversion_width);

                        @media(max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.showed_and_upcoming_consult_appointment_chart {
                        width: var(--showed_and_upcoming_consult_appointment_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        @media (max-width: $desktop_view) {
                            .tooltip_sec {
                                position: unset;
                                min-height: 16px;
                                height: 100%;
                            }
                        }
                    }


                    &.avaliable_appointment_line_chart {
                        width: var(--avaliable_appointment_line_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        @media (max-width: $desktop_view) {
                            .tooltip_sec {
                                position: unset;
                                // min-height: 16px;
                                height: 100%;
                            }
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    padding-right: 2px;
                                }
                            }

                            @media (max-width: $large_mobile) {
                                .select_box_sec {
                                    min-width: unset;
                                    width: unset;
                                }

                                .css-13cymwt-control {
                                    .css-1fdsijx-ValueContainer {
                                        padding: 2px 3px;
                                    }

                                    .css-1xc3v61-indicatorContainer {
                                        padding: 8px 2px;

                                        svg {
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.schedule_first_chart {
                        width: var(--schedule_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.insurance_first_chart {
                        width: var(--insurance_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.open_action_item_average_turn_around_time_chart {
                        width: var(--open_action_item_average_turn_around_time_width);

                        .dash_toggle {
                            .toggle-switch__slider::before {
                                content: '';
                            }
                        }

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.daily_open_action_items_report_chart {
                        width: var(--showed_vs_noshow_vs_cancelled_appointments_report_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;
                        }
                    }

                    &.open_action_items_resolved_by_user_chart {
                        width: var(--open_action_item_average_turn_around_time_width);

                        .dash_toggle {
                            .toggle-switch__slider::before {
                                content: '';
                            }
                        }

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }


                    &.showed_consults_pie_chart {
                        width: var(--showed_consults_pie_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_section {
                            position: relative;

                            .showed_consults_title_New_Patient_Paperwork {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 70px;
                                text-align: center;
                                position: absolute;
                                top: 50px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }

                            }

                            .showed_consults_title {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 5px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            @media(max-width: $mobileLarge) {
                                .chart_sec {
                                    min-height: 420px;
                                }
                            }

                            .chart_sec {
                                max-width: 750px;
                                margin: 0 auto;
                                width: 100%;
                            }
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;
                            }
                        }
                    }

                    &.schedule_first_funnel_report_over_time {
                        width: var(--schedule_first_funnel_report_over_time_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            margin: 0px 12px 0 0;
                            display: flex;

                            .toggle-switch__slider::before {
                                position: absolute;
                                height: 26px;
                                width: 26px;
                                left: 4px;
                                bottom: 4px;
                                background-color: white;
                                border-radius: 50%;
                                transition: all 0.3s;
                                content: "#";
                                /* Default content */
                                text-align: center;
                                padding-top: 3px;
                            }

                            .toggle-switch input:checked+.toggle-switch__slider::before {
                                transform: translateX(26px);
                                content: "%";
                                /* Change content when checked */
                            }

                        }
                    }

                    &.Insurance_first_funnel_report_over_time {
                        width: var(--Insurance_first_funnel_report_over_time_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            margin: 0px 12px 0 0;
                            display: flex;

                            .toggle-switch__slider::before {
                                position: absolute;
                                height: 26px;
                                width: 26px;
                                left: 4px;
                                bottom: 4px;
                                background-color: white;
                                border-radius: 50%;
                                transition: all 0.3s;
                                content: "#";
                                /* Default content */
                                text-align: center;
                                padding-top: 3px;
                            }

                            .toggle-switch input:checked+.toggle-switch__slider::before {
                                transform: translateX(26px);
                                content: "%";
                                /* Change content when checked */
                            }

                        }
                    }


                    &.upcoming_consults_pie_chart {
                        width: var(--upcoming_consults_pie_chart_width);

                        @media (max-width: 1535px) and (min-width: $laptops) {
                            .filter_sec {
                                .select_box_sec {
                                    min-width: unset;
                                    width: unset;
                                }

                                .css-13cymwt-control {
                                    .css-1fdsijx-ValueContainer {
                                        padding: 2px 3px;
                                    }

                                    .css-1xc3v61-indicatorContainer {
                                        padding: 8px 2px;

                                        svg {
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_section {
                            position: relative;

                            .upcoming_consults_title {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 5px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 353px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            @media (max-width: $mobileLarge) {
                                .chart_sec {
                                    min-height: 420px;
                                }
                            }

                            .chart_sec {
                                max-width: 750px;
                                margin: 0 auto;
                                width: 100%;
                            }
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;

                                .dash_toggle {
                                    &:first-child {
                                        .tooltip_title_sec_bubble {
                                            right: 175px !important;

                                            .tooltip_title_hidden_bubble {
                                                @media (max-width: $mobile_small) {
                                                    left: 0%;
                                                }

                                                &::before {
                                                    left: 140px !important;
                                                }
                                            }
                                        }
                                    }

                                    .toggle-switch__slider::before {
                                        content: "";
                                        background-image: url(../image/funnel.png);
                                        background-size: 12px;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }

                                    &.disabled_class {
                                        .toggle-switch__slider::before {
                                            background-color: #6a6a6a !important;
                                        }

                                        .toggle-switch {
                                            .toggle-switch__slider {
                                                background-color: #8d8d8d !important;
                                            }
                                        }

                                        .tooltip_title_sec_bubble {
                                            right: auto;
                                            left: -250px;

                                            @media (max-width: $mobile_small) {
                                                left: -232px;
                                            }

                                            .tooltip_title_hidden_bubble {
                                                &::before {
                                                    right: 75px;
                                                    left: auto;
                                                }
                                            }
                                        }
                                    }

                                    &:first-child {
                                        .toggle-switch__slider::before {
                                            content: "";
                                            background-size: 14px;
                                            background-image: url(../image/right-down.png);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.appointment_type {
                        width: var(--appointment_type_width);

                        @media (max-width: 1535px) and (min-width: $laptops) {
                            .filter_sec {
                                .select_box_sec {
                                    min-width: unset;
                                    width: unset;
                                }

                                .css-13cymwt-control {
                                    .css-1fdsijx-ValueContainer {
                                        padding: 2px 3px;
                                    }

                                    .css-1xc3v61-indicatorContainer {
                                        padding: 8px 2px;

                                        svg {
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_section {
                            position: relative;

                            .upcoming_consults_title {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 5px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 353px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            @media (max-width: $mobileLarge) {
                                .chart_sec {
                                    min-height: 420px;
                                }
                            }

                            .chart_sec {
                                max-width: 750px;
                                margin: 0 auto;
                                width: 100%;
                            }
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;

                                .dash_toggle {
                                    &:first-child {
                                        .tooltip_title_sec_bubble {
                                            right: 175px !important;

                                            .tooltip_title_hidden_bubble {
                                                @media (max-width: $mobile_small) {
                                                    left: 0%;
                                                }

                                                &::before {
                                                    left: 140px !important;
                                                }
                                            }
                                        }
                                    }

                                    .toggle-switch__slider::before {
                                        content: "";
                                        background-image: url(../image/funnel.png);
                                        background-size: 12px;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }

                                    &.disabled_class {
                                        .toggle-switch__slider::before {
                                            background-color: #6a6a6a !important;
                                        }

                                        .toggle-switch {
                                            .toggle-switch__slider {
                                                background-color: #8d8d8d !important;
                                            }
                                        }

                                        .tooltip_title_sec_bubble {
                                            right: auto;
                                            left: -250px;

                                            @media (max-width: $mobile_small) {
                                                left: -232px;
                                            }

                                            .tooltip_title_hidden_bubble {
                                                &::before {
                                                    right: 75px;
                                                    left: auto;
                                                }
                                            }
                                        }
                                    }

                                    &:first-child {
                                        .toggle-switch__slider::before {
                                            content: "";
                                            background-size: 14px;
                                            background-image: url(../image/right-down.png);
                                        }
                                    }
                                }
                            }
                        }
                    }


                    &.new_patient_paperwork_submission_rate {
                        width: var(--new_patient_paperwork_submission_rate_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_section {
                            position: relative;

                            .showed_consults_title_New_Patient_Paperwork {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 70px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            .showed_consults_title {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 70px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media (max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            @media(max-width: $mobileLarge) {
                                .chart_sec {
                                    min-height: 420px;
                                }
                            }

                            .chart_sec {
                                max-width: 750px;
                                margin: 0 auto;
                                width: 100%;
                            }
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;
                            }
                        }
                    }


                    &.booked_appointment_chart {
                        width: var(--booked_appointment_heat_map_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;
                            // @media(max-width: $ipad) {
                            //     .select_box_sec {
                            //         min-width: 88px;
                            //         padding-right: 2px;
                            //     }
                            // }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;

                            >span {
                                margin-left: 0px;
                            }
                        }
                    }

                    &.showed_appointment_chart {
                        width: var(--showed_appointment_heat_map_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;

                            >span {
                                margin-left: 0px;
                            }
                        }
                    }

                    &.show_rate_appointment_chart {
                        width: var(--show_rate_appointment_heat_map_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;

                            >span {
                                margin-left: 0px;
                            }
                        }
                    }

                    &.filled_vs_unfilled_appointment_chart {
                        width: var(--filled_vs_unfilled_appointment_slots_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;
                        }
                    }

                    &.showed_vs_no_show_vs_cancelled_appointment_chart {
                        width: var(--showed_vs_noshow_vs_cancelled_appointments_report_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;
                        }
                    }

                    &.insurance_report_chart {
                        width: var(--insurance_report_funnel_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.facebook_ads_cost_per_conversion_funnel_chart {
                        width: var(--facebook_ads_cost_per_conversion_funnel_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.lap_band_facebook_ads_per_conversion_funnel_chart {
                        width: var(--lap_band_facebook_ads_per_conversion_funnel_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.google_ads_cost_per_conversion_funnel_chart {
                        width: var(--google_ads_cost_per_conversion_funnel_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.lap_band_google_ads_per_conversion_funnel_chart {
                        width: var(--lap_band_google_ads_per_conversion_funnel_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.showed_consults_by_lead_source {
                        width: var(--showed_consults_by_lead_source_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;
                            }
                        }
                    }

                    &.upcoming_consults_by_lead_source {
                        width: var(--upcoming_consults_by_lead_source_width);

                        @media (max-width: 1535px) and (min-width: $laptops) {
                            .filter_sec {
                                .select_box_sec {
                                    min-width: unset;
                                    width: unset;
                                }

                                .css-13cymwt-control {
                                    .css-1fdsijx-ValueContainer {
                                        padding: 2px 3px;
                                    }

                                    .css-1xc3v61-indicatorContainer {
                                        padding: 8px 2px;

                                        svg {
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                        }

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;

                                .dash_toggle {
                                    margin: 0 5px 0 0;

                                    &:first-child {
                                        .tooltip_title_sec_bubble {
                                            right: 175px !important;

                                            .tooltip_title_hidden_bubble {
                                                @media (max-width: $mobile_small) {
                                                    left: 0%;
                                                }

                                                &::before {
                                                    left: 140px !important;
                                                }
                                            }
                                        }
                                    }

                                    .toggle-switch {
                                        margin: 0;
                                    }

                                    .toggle-switch__slider::before {
                                        content: "";
                                        background-image: url(../image/funnel.png);
                                        background-size: 12px;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                    }

                                    &.disabled_class {
                                        .toggle-switch__slider::before {
                                            background-color: #6a6a6a !important;
                                        }

                                        .toggle-switch {
                                            .toggle-switch__slider {
                                                background-color: #8d8d8d !important;
                                            }
                                        }

                                        .tooltip_title_sec_bubble {
                                            right: auto;
                                            left: -250px;

                                            @media (max-width: $mobile_small) {
                                                left: -232px;
                                            }

                                            .tooltip_title_hidden_bubble {
                                                &::before {
                                                    right: 75px;
                                                    left: auto;
                                                }
                                            }
                                        }
                                    }

                                    &:first-child {
                                        .toggle-switch__slider::before {
                                            content: "";
                                            background-image: url(../image/right-down.png);
                                            background-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.scheduling_link_to_showed_consult_rate {
                        width: var(--scheduling_link_to_showed_consult_rate_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        @include responsive_filter;
                    }

                    &.gauge_chart {
                        width: var(--available_appointment_gauge_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.phone_flow_schedule_first_chart {
                        width: var(--phone_flow_schedule_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.phone_flow_insurance_first_chart {
                        width: var(--phone_flow_insurance_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.showed_appointment_google_analytics_chart {
                        width: var(--showed_appointment_google_analytics_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.referral_report_schedule_first_chart {
                        width: var(--referral_report_schedule_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.referral_report_insurance_first_chart {
                        width: var(--referral_report_insurance_first_chart_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.follow_up_impact_report_chart {

                        width: var(--follow_up_impact_report_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .filter_sec {
                            max-width: unset;
                            flex-wrap: nowrap;
                            width: auto;

                            @media (max-width: $mobile) {
                                .select_box_sec {
                                    min-width: 88px;
                                    padding-right: 2px;
                                }
                            }
                        }

                        .business_hours_span {
                            margin-left: 0px;
                            align-self: center;

                            >span {
                                margin-left: 0px;
                            }
                        }

                        .dash_toggle {
                            .toggle-switch__slider::before {
                                content: "%";
                            }

                        }

                    }

                    &.click_to_call_scheduled_showed_consults_chart {
                        width: var(--click_to_call_scheduled_showed_consults_width);

                        @media(max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_section {
                            position: relative;

                            .showed_consults_title_New_Patient_Paperwork {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 5px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media(max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            .showed_consults_title {
                                color: #fff;
                                font-size: 34px;
                                padding-top: 5px;
                                text-align: center;
                                position: absolute;
                                top: 80px;
                                z-index: 2;
                                max-width: 310px;
                                width: 100%;

                                @media(max-width: $mobileLarge) {
                                    max-width: $w_100;
                                    position: unset;
                                    font-size: 24px;
                                }
                            }

                            .Click_To_Call_Scheduled_ShowedConsults_Title {
                                top: 30px;
                            }

                            @media(max-width: $mobileLarge) {
                                .chart_sec {
                                    min-height: 420px;
                                }
                            }

                            .chart_sec {
                                max-width: 750px;
                                margin: 0 auto;
                                width: 100%;
                            }
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;
                            }
                        }
                    }

                    &.click_to_call_scheduled_showed_consults_by_lead_source_chart {
                        width: var(--click_to_call_scheduled_showed_consults_by_lead_source_width);

                        @media(max-width: $laptops) {
                            width: $w_100;
                        }

                        .chart_title_sec {
                            .filter_sec {
                                align-items: center;
                                max-width: unset;
                                flex-wrap: nowrap;
                            }
                        }
                    }

                    &.click_to_call_scheduled_showed_consults_form_submission_date_chart {
                        width: var(--click_to_call_scheduled_showed_consults_form_submission_date_width);

                        @media(max-width: $laptops) {
                            width: $w_100;
                        }

                        @media(max-width:$desktop_view) {
                            .tooltip_sec {
                                position: unset;
                                // min-height: 16px;
                                height: 100%;
                            }
                        }
                    }

                    &.click_to_call_funnel_chart {
                        width: var(--click_to_call_funnel_width);

                        @media(max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    @media(max-width: $desktop_view) {

                        width: $w_100;
                    }


                    &.facebook_ads_cost_per_conversion_chart {
                        width: var(--facebook_ads_cost_per_conversion_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.lap_band_facebook_ads_per_conversion_chart {
                        width: var(--lap_band_facebook_ads_per_conversion_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.google_ads_cost_per_conversion_chart {
                        width: var(--google_ads_cost_per_conversion_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.lap_band_google_ads_per_conversion_chart {
                        width: var(--lap_band_google_ads_per_conversion_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }
                    }

                    &.Top_Performing_Ads_chart {
                        width: var(--Top_Performing_Ads_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            .toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 114px;
                            }

                            .no_info_toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 57px;
                            }


                            .toggle-switch__slider::before {
                                content: '';
                            }

                            .toggle-switch input:checked+.toggle-switch__slider {
                                background-color: #edd20b;
                            }

                            .no_info_toggle-switch input:checked+.no_info_toggle-switch__slider {
                                background-color: #fa8c16;
                            }
                        }

                        // @media (max-width: $desktop_view) {
                        //     .tooltip_sec {
                        //         position: unset;
                        //         min-height: 16px;
                        //         height: 100%;
                        //     }
                        // }
                    }

                    &.lap_band_Top_Performing_Ads_chart {
                        width: var(--lap_band_Top_Performing_Ads_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            .toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 114px;
                            }

                            .no_info_toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 57px;
                            }


                            .toggle-switch__slider::before {
                                content: '';
                            }

                            .toggle-switch input:checked+.toggle-switch__slider {
                                background-color: #edd20b;
                            }

                            .no_info_toggle-switch input:checked+.no_info_toggle-switch__slider {
                                background-color: #fa8c16;
                            }
                        }

                        // @media (max-width: $desktop_view) {
                        //     .tooltip_sec {
                        //         position: unset;
                        //         min-height: 16px;
                        //         height: 100%;
                        //     }
                        // }
                    }

                    &.Conversion_Funnel_By_Insurance_company_chart {
                        width: var(--Conversion_Funnel_By_Insurance_company_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }


                        .dash_toggle {
                            margin: 0px 12px 0 0;
                            display: flex;

                            .toggle-switch__slider::before {
                                position: absolute;
                                height: 26px;
                                width: 26px;
                                left: 4px;
                                bottom: 4px;
                                background-color: white;
                                border-radius: 50%;
                                transition: all 0.3s;
                                content: "#";
                                /* Default content */
                                text-align: center;
                                padding-top: 3px;
                            }

                            .toggle-switch input:checked+.toggle-switch__slider::before {
                                transform: translateX(26px);
                                content: "%";
                                /* Change content when checked */
                            }

                        }

                        // @media (max-width: $desktop_view) {
                        //     .tooltip_sec {
                        //         position: unset;
                        //         min-height: 16px;
                        //         height: 100%;
                        //     }
                        // }
                    }

                    &.non_converting_Ads_chart {
                        width: var(--non_converting_Ads_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            .toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 114px;
                            }


                            .no_info_toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 57px;
                            }

                            .toggle-switch__slider::before {
                                content: '';
                            }

                            .toggle-switch input:checked+.toggle-switch__slider {
                                background-color: #edd20b;
                            }

                            .no_info_toggle-switch input:checked+.no_info_toggle-switch__slider {
                                background-color: #fa8c16;
                            }
                        }
                    }

                    &.lap_band_non_converting_Ads_chart {
                        width: var(--lap_band_non_converting_Ads_width);

                        @media (max-width: $laptops) {
                            width: $w_100;
                        }

                        .dash_toggle {
                            .toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 114px;
                            }


                            .no_info_toggle_btn_text {
                                margin: 0;
                                font-size: 14px;
                                line-height: 30px;
                                color: #ffffff;
                                min-width: 57px;
                            }

                            .toggle-switch__slider::before {
                                content: '';
                            }

                            .toggle-switch input:checked+.toggle-switch__slider {
                                background-color: #edd20b;
                            }

                            .no_info_toggle-switch input:checked+.no_info_toggle-switch__slider {
                                background-color: #fa8c16;
                            }
                        }
                    }
                }

                @media (min-width: $larges) {
                    margin-left: 280px;
                    margin-right: 20px;
                }

                @media (max-width: $larges) {
                    padding-left: 15px;
                    padding-right: 15px;
                    margin: 0;
                }
            }
        }

        .FlagEoor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 470px;
            color: white;
        }



        // .nav_close_btn {
        //     display: none;
        //     position: fixed;
        //     top: 10px;
        //     left: -100%;
        //     width: 25px;
        //     height: 25px;
        //     border-radius: 5px;
        //     justify-content: center;
        //     align-items: center;
        //     z-index: 2;
        //     padding: 0;
        //     border: none;

        //     >svg {
        //         width: 22px;
        //         height: 22px;
        //     }

        //     @media (max-width: 992px) {
        //         display: flex;
        //     }
        // }
    }
}

g {
    &.amcharts-Container {
        &.amcharts-Tooltip {
            .amcharts-Label {
                tspan {
                    color: $black;
                    // font-family: "poppins-Bold";
                    padding: 0;
                    margin: 0;
                    line-height: $line_height_100_p;

                    @media (max-width: 768px) {
                        font-size: 11px;
                    }

                    @media (max-width: $desktop_view) {
                        line-height: $line_height_140_p;
                    }
                }
            }
        }
    }
}

//zoom change
@media (min-width: $large) {
    body {
        zoom: 80%;

        .multiline_chart,
        .showed_and_upcoming_consult_report_chart,
        .showed_and_upcoming_consult_appointment_chart,
        .website_conversion_google_analytics_chart,
        .click_to_call_website_conversion_chart,
        .click_to_call_scheduled_showed_consults_form_submission_date_chart,
        .follow_up_impact_report_chart,
        .open_action_item_average_turn_around_time_chart,
        .daily_open_action_items_report_chart,
        .facebook_ads_cost_per_conversion_chart,
        .lap_band_facebook_ads_per_conversion_chart,
        .google_ads_cost_per_conversion_chart,
        .lap_band_google_ads_per_conversion_chart,
        .Top_Performing_Ads_chart,
        .lap_band_Top_Performing_Ads_chart,
        .non_converting_Ads_chart,
        .lap_band_non_converting_Ads_chart,
        .schedule_first_funnel_report_over_time,
        .Insurance_first_funnel_report_over_time {
            .chart_sec {
                zoom: 125%;
            }
        }
    }
}

@media (min-width: 1270px) {
    .daterangepicker.dropdown-menu {
        position: fixed;
        top: 50px !important;
    }
}

@media (min-width: $large) and (max-width: 1270px) {
    .daterangepicker.dropdown-menu {
        position: fixed;
        top: 90px !important;
    }
}

.swal-modal {
    background: #1F1F1F;
}

.swal-title {
    color: white;
}

.swal-text {
    color: white;
}

.btn_resolve_alert_confirm {
    background: #FA8C16;
    color: #ffffff;
    min-width: 170px;
    cursor: pointer;
    font-weight: 200;
    box-shadow: none;
    box-shadow: none !important;
}

.custom_swal_container {
    width: 694px;
}


@media (max-width: 600px) {
    .custom_swal_container {
        width: 90%;
        /* Adjust width for smaller screens */
    }
}

.btn_resolve_alert_confirm:not([disabled]):hover {
    background: #FA8C16;
}

.btn_resolve_alert {
    background: #FA8C16;
    border: 1px solid #FA8C16;
    padding: 7px 7px;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    transition: 0.4s ease;
    cursor: pointer;
    margin-right: 19px;
    margin-top: 3px;
    width: 200px;
}

// @media (max-width: 992px){

// }

@media (max-width: 767px) {

    /* Mobile styles */
    .btn_resolve_alert {
        width: 361px;
    }
}